import 'src/Utils/Utils'
import 'regenerator-runtime/runtime'
import React, { Component } from 'react'
import { Amplify } from 'aws-amplify'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { isMobile } from 'react-device-detect'

import MainScreen from 'src/Screens/Main/MainScreen'
import { panelStore } from './Store/panelStore'
import { persistor } from 'src/Store/panelStore'
import appConfig from './Configs/appConfig'

import MobileMessageForUser from 'src/UIComponents/MobileMessageForUser/MobileMessageForUser'

import { datadogRum } from '@datadog/browser-rum'

Amplify.configure({
  Auth: {
    region: appConfig.cognitoRegion,
    userPoolId: appConfig.cognitoUserPoolId,
    userPoolWebClientId: appConfig.cognitoUserPoolClientId
  }
})

if (appConfig.datadogApplicationId && appConfig.datadogClientToken) {
  datadogRum.init({
    applicationId: appConfig.datadogApplicationId,
    clientToken: appConfig.datadogClientToken,
    site: 'datadoghq.com',
    service: 'dash',
    env: appConfig.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    actionNameAttribute: 'data-tracking',
    replaySampleRate: 100
  })
}

class App extends Component {
  constructor(props) {
    datadogRum.startSessionReplayRecording()
    super(props)
  }

  render() {
    return (
      <Provider store={panelStore}>
        <PersistGate persistor={persistor}>
          {isMobile ? <MobileMessageForUser /> : <MainScreen />}
        </PersistGate>
      </Provider>
    )
  }
}

export default hot(App)
