import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom'

import Button from 'src/UIComponents/Button/ButtonDefault'
import Sidebar from 'src/UIComponents/Sidebar/Sidebar'
import AddPosComponentForm from './AddPosComponentForm'

const AddPosComponent = ({ buttonInversed }) => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch({
    path: '*/habilitar-pos'
  })

  const [showSidebar, setShowSidebar] = useState(!!match)

  useEffect(() => {
    if (match) {
      setTimeout(() => {
        setShowSidebar(true)
      }, 500)
    }
  }, [])

  const handleClose = () => {
    setShowSidebar(false)

    setTimeout(() => {
      history.push(match.params[0])
    }, 500)
  }

  const handleClick = () => {
    history.push(`${location.pathname}/habilitar-pos`)

    setTimeout(() => {
      setShowSidebar(true)
    }, 500)
  }

  return (
    <>
      <Button ghost={buttonInversed} outline={buttonInversed} widthAuto onClick={handleClick}>Habilitar POS</Button>
      {
        match && <Sidebar show={showSidebar}>
          <AddPosComponentForm handleClose={handleClose} />
        </Sidebar>
      }
    </>
  )
}

export default AddPosComponent
