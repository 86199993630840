import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import t from 'tcomb-form'
import formValidators from '../../Utils/formValidators'
import { pairPOS, resetSearchPOSSeller } from '../../Actions/pos'
import { getSellersSelect } from '../../Actions/sellers'

import TokenFactory from '../FormFactories/TokenFactory'
import { SelectSeller } from '../SelectSeller/SelectSeller'

import FormZ from '../../UIComponents/FormZ/FormZ'
import Grid from '../../UIComponents/Grid/Grid'
import AddPos from '../../UIComponents/AddPos/AddPos'
import SuccessCard from '../../UIComponents/SuccessCard/SuccessCard'
import { POSMessageSuccess } from '../../UIComponents/SuccessMessages/SuccessMessages'
import AddPosTemplate from '../../UIComponents/TemplatesForm/AddPosTemplate'
import HeaderSidebar from '../../UIComponents/HeaderSidebar/HeaderSidebar'
import DisplaySeller from '../../UIComponents/DisplaySeller/DisplaySeller'
import SidebarContainer from 'src/UIComponents/Sidebar/SidebarContainer'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import { mapValues } from 'lodash'

const AddPosComponentForm = ({ handleClose }) => {
  const dispatch = useDispatch()

  const { pairPOSRequesting, pairPOSSuccess, pairPOSError, marketplaceId } =
    useSelector(({ pos, marketplace }) => {
      return {
        pairPOSRequesting: pos.pairPOSRequesting,
        pairPOSSuccess: pos.pairPOSSuccess,
        pairPOSError: pos.pairPOSError,
        marketplaceId:
          marketplace && marketplace.details && marketplace.details.id
      }
    })

  const [state, setState] = useState({
    seller: null,
    showValidation: false,
    hasError: false,
    errorMessage: '',
    display: false,
    values: {
      token: ''
    },
    options: {
      template: (locals) => AddPosTemplate(locals),
      fields: {
        token: {
          label: 'Token POS (8 dígitos)',
          factory: TokenFactory,
          autocomplete: false,
          config: {
            id: 'token'
          }
        }
      }
    }
  })

  const struct = t.struct({
    token: formValidators.Token
  })

  useEffect(() => {
    if (pairPOSError && pairPOSError.status === 400) {
      setState({
        ...state,
        showValidation: true
      })
    }
  }, [pairPOSError])

  const selectSeller = (item) =>
    setState({ ...state, seller: item, display: true })

  const closeSelectSeller = () => {
    setState({
      ...state,
      seller: null,
      display: false
    })
    dispatch(getSellersSelect())
  }

  const handleToken = (values) => {
    if (pairPOSError) {
      dispatch(resetSearchPOSSeller())
    }

    let { options } = state
    options.fields = mapValues(options.fields, (field) => {
      return {
        ...field,
        hasError: false,
        error: null
      }
    })
    setState({
      ...state,
      values,
      options,
      showValidation: false
    })
  }

  const getFieldValidation = (fieldName) => {
    if (!state.values[fieldName])
      return { message: 'Você esqueceu de preencher aqui?' }

    return t.validate(state.values, struct).errors.find((error) => {
      return error.path.includes(fieldName)
    })
  }

  const clearForm = () =>
    setState({ ...state, seller: null, values: { token: '' } })

  const handleError = () =>
    setState({ ...state, hasError: false, errorMessage: '' })

  const addPos = () => {
    const { seller, values } = state
    const isFormValid = t.validate(values, struct).isValid()
    const isSellerSelected = seller && seller.id

    if (!isSellerSelected) {
      setState((prev) => ({
        ...prev,
        hasError: true,
        errorMessage: 'Você esqueceu de buscar aqui?'
      }))
    }

    if (!isFormValid || !isSellerSelected) {
      setState((prev) => ({ ...prev, showValidation: true }))
      return
    }

    dispatch(pairPOS(marketplaceId, seller.id, values.token))
  }

  const handleOnClose = () => {
    dispatch(resetSearchPOSSeller())
    handleClose()
  }

  const {
    seller,
    values,
    showValidation,
    options,
    hasError,
    errorMessage,
    display
  } = state

  if (showValidation) {
    options.fields = mapValues(options.fields, (field, key) => {
      const fieldValidation = getFieldValidation(key)

      if (key === 'token') {
        if (pairPOSError) {
          if (pairPOSError.status === 400) {
            return {
              ...field,
              hasError: true,
              error: pairPOSError.message
            }
          }
        }
      }

      return {
        ...field,
        hasError: fieldValidation && fieldValidation.hasOwnProperty('message'),
        error: fieldValidation && fieldValidation.message
      }
    })
  }

  if (pairPOSSuccess) {
    return (
      <Fragment>
        <HeaderSidebar handleClose={handleOnClose} />
        <SidebarContainer>
          <Grid.Row auto>
            <SuccessCard
              sizeButton='192px'
              onClick={() => {
                clearForm()
                dispatch(resetSearchPOSSeller())
              }}
              textButton='Habilitar outro POS'
              data-tracking='Habilitar outro POS'
            >
              <POSMessageSuccess
                name={
                  seller.type === 'individual'
                    ? `${seller.first_name} ${seller.last_name}`
                    : `${seller.business_name}`
                }
              />
            </SuccessCard>
          </Grid.Row>
        </SidebarContainer>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <HeaderSidebar
        title={
          <>
            <strong>Habilitar</strong> POS
          </>
        }
        handleClose={handleOnClose}
      />
      <Grid.Row auto>
        <AddPos>
          <Grid noPadding>
            <Grid.Row height='60px'>
              <Grid.Col cols={16}>
                {!seller && (
                  <SelectSeller
                    action={selectSeller}
                    hasError={hasError}
                    errorMessage={errorMessage}
                    handleError={handleError}
                    data-test='select-seller-pos'
                    data-tracking='select-seller-pos'
                    noBox
                  />
                )}
                {seller && display && (
                  <DisplaySeller seller={seller} onClick={closeSelectSeller} />
                )}
              </Grid.Col>
            </Grid.Row>
            <Grid.Row />
            <Grid.Row auto>
              <FormZ
                options={options}
                struct={struct}
                values={values}
                onChange={handleToken}
                labelButton='Habilitar POS'
                isRequesting={pairPOSRequesting}
                onSubmitForm={addPos}
                sizeButton='152px'
                data-tracking='Habilitar POS'
              />
            </Grid.Row>
            {pairPOSError && pairPOSError.status !== 400 && (
              <Grid.Row auto>
                <AlertMessage type='error' error={pairPOSError} />
              </Grid.Row>
            )}
          </Grid>
        </AddPos>
      </Grid.Row>
    </Fragment>
  )
}

AddPosComponentForm.propTypes = {
  handleClose: PropTypes.func
}

export default AddPosComponentForm
