import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import t from 'tcomb-form'
import moment from 'moment'
import { mapValues } from 'lodash'
import formValidators from '../../Utils/formValidators'
import { updateBusiness } from '../../Actions/seller'

import DocumentFactory from '../FormFactories/DocumentFactory'
import InputDefaultFactory from '../FormFactories/InputDefaultFactory'
import PhoneFactory from '../FormFactories/PhoneFactory'
import DatePickerFactory from '../FormFactories/DatePickerFactory'
import PostalCodeFactory from '../FormFactories/PostalCodeFactory'

import { getProfileFromMarketplace } from 'src/Helpers/Permissions'
import Grid from '../../UIComponents/Grid/Grid'
import Title from '../../UIComponents/Title/Title'
import AccountTemplate from '../../UIComponents/TemplatesForm/AccountTemplate'
import Button from '../../UIComponents/Button/ButtonDefault'
import FormZ from '../../UIComponents/FormZ/FormZ'
import TitleInfoPage from '../../UIComponents/Title/TitleInfoPage'
import styled from 'styled-components'

const mapDispatchToProps = dispatch => bindActionCreators({ updateBusiness }, dispatch)

const mapStateToProps = (state) => {
  const permissions = state.user.permissions
  const marketplaceId = (state.context.marketplace && state.context.marketplace.id) || state.marketplace.id
  const marketplace = state.marketplace && state.marketplace.details
  const sellerOwnerId = marketplace && state.marketplace.details.customer.id
  const updateSellerOwnerRequest = state.marketplace.updateSellerOwnerRequest
  const updateSellerOwnerSuccess = state.marketplace.updateSellerOwnerSuccess

  return {
    permissions,
    marketplaceId,
    marketplace,
    sellerOwnerId,
    updateSellerOwnerRequest,
    updateSellerOwnerSuccess
  }
}

class AccountComponent extends Component {
  constructor (props) {
    super(props)
    this.INITIAL_STATE = {
      document: props.marketplace.customer.ein || '',
      phoneNumber: props.marketplace.customer.business_phone || '',
      email: props.marketplace.customer.business_email || '',
      statement: props.marketplace.customer.statement_descriptor || '',
      businessName: props.marketplace.customer.business_name || '',
      site: props.marketplace.customer.business_website || '',
      postalCode: props.marketplace.customer.business_address.postal_code || '',
      line1: props.marketplace.customer.business_address.line1 || '',
      line2: props.marketplace.customer.business_address.line2 || '',
      line3: props.marketplace.customer.business_address.line3 || '',
      neighborhood: props.marketplace.customer.business_address.neighborhood || '',
      city: props.marketplace.customer.business_address.city || '',
      state: props.marketplace.customer.business_address.state || '',
      nameOwner: `${props.marketplace.customer.owner.first_name} ${props.marketplace.customer.owner.last_name}`,
      birthDate: moment(props.marketplace.customer.owner.birthdate).toDate() || '',
      taxPayerId: props.marketplace.customer.owner.taxpayer_id || '',
      phoneOwner: props.marketplace.customer.owner.phone_number || '',
      emailOwner: props.marketplace.customer.owner.email || '',
      description: props.marketplace.customer.business_description || '',
      businessOpeningDate: t.validate(moment(props.marketplace.customer.business_opening_date, 'YYYY-MM-DD').toDate(), formValidators.OpeningDate).isValid()
        ? moment(props.marketplace.customer.business_opening_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
        : ''
    }
    this.state = {
      disabled: true,
      viewMode: true,
      showValidation: false,
      values: this.INITIAL_STATE
    }
    this.struct = t.struct({
      document: t.maybe(t.String),
      phoneNumber: formValidators.Phone,
      statement: t.maybe(t.String),
      businessName: t.maybe(t.String),
      email: t.maybe(t.String),
      site: t.maybe(t.String),
      postalCode: t.maybe(t.String),
      line1: t.maybe(t.String),
      line2: t.maybe(t.String),
      line3: t.maybe(t.String),
      neighborhood: t.maybe(t.String),
      city: t.maybe(t.String),
      state: t.maybe(t.String),
      nameOwner: t.maybe(t.String),
      birthDate: formValidators.BirthDate,
      taxPayerId: t.maybe(t.String),
      phoneOwner: formValidators.Phone,
      emailOwner: t.maybe(t.String),
      description: formValidators.String,
      businessOpeningDate: t.maybe(t.String)
    })

    this.requiredFields = ['phoneOwner', 'phoneNumber', 'birthDate', 'description']
  }

  onChange = values => this.setState({ values, showValidation: false })

  onSubmitForm = () => {
    const { values } = this.state
    const { site, phoneNumber, description, phoneOwner, birthDate } = values
    const { updateBusiness, marketplace, sellerOwnerId } = this.props
    const isFormValid = t.validate(values, this.struct).isValid()

    if (!isFormValid) {
      this.setState({ showValidation: true })
      return
    }

    const data = {
      business_website: site,
      business_phone: phoneNumber,
      business_description: description,
      owner: {
        phone_number: phoneOwner,
        birthdate: birthDate
      }
    }

    updateBusiness(marketplace.customer.type, sellerOwnerId, data)
  }

  componentWillReceiveProps (props) {
    const { updateSellerOwnerSuccess } = props
    const { updateSellerOwnerSuccess: currentSellerDetailSuccess } = this.props

    if (!currentSellerDetailSuccess && updateSellerOwnerSuccess) {
      this.setState({ disabled: true, viewMode: true })
    }
  }

  editForm = () => this.setState({ disabled: !this.state.disabled, viewMode: false })

  cancelForm = () => this.setState({ disabled: true, viewMode: true, values: this.INITIAL_STATE })

  getFieldValidation = fieldName => {
    const { values } = this.state
    if (!values[fieldName] && this.requiredFields.includes(fieldName)) {
      return {
        message: 'Você esqueceu de preencher aqui?'
      }
    }

    return t.validate(values, this.struct).errors.find(error => {
      return error.path.includes(fieldName)
    })
  }

  render () {
    const { values, disabled, viewMode, showValidation } = this.state
    const { updateSellerOwnerRequest, permissions, marketplaceId } = this.props

    const profile = getProfileFromMarketplace(marketplaceId, permissions)
    const hideEdit = profile === 'full_visualization'
    let options = {
      template: (locals) => AccountTemplate(locals, this.editForm, profile === 'full_visualization' ? false : disabled),
      fields: {
        document: {
          label: 'CNPJ',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'ein',
            placeholder: ' ',
            documentType: 'ein',
            valid: false,
            disabled: true,
            lock: viewMode
          }
        },
        businessName: {
          label: 'Razão Social',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'business-name',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        statement: {
          label: 'Nome Fantasia',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            placeholder: ' ',
            id: 'statement',
            disabled: true,
            lock: viewMode
          }
        },
        phoneNumber: {
          label: 'Telefone do Marketplace',
          factory: PhoneFactory,
          autocomplete: false,
          config: {
            id: 'business-phone-number',
            placeholder: ' ',
            disabled: disabled
          }
        },
        email: {
          label: 'Email do Marketplace',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'email',
            valid: false,
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        site: {
          label: 'Site (opcional)',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'site',
            placeholder: ' ',
            disabled: disabled
          }
        },
        postalCode: {
          label: 'CEP',
          factory: PostalCodeFactory,
          autocomplete: false,
          config: {
            id: 'postal-code',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        line1: {
          label: 'Endereço',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'line-1',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        line2: {
          label: 'Número',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'line-2',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        line3: {
          label: 'Complemento',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'line-3',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        neighborhood: {
          label: 'Bairro',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'neighborhood',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        city: {
          label: 'Cidade',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'city',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        state: {
          label: 'Estado',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 2
          },
          config: {
            id: 'state',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        nameOwner: {
          label: 'Nome Completo',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'full-name',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        },
        birthDate: {
          label: 'Data de Nascimento',
          factory: DatePickerFactory,
          autocomplete: false,
          config: {
            id: 'birthdate',
            placeholder: ' ',
            maxDate: moment().subtract(18, 'years').toDate(),
            disabled: disabled
          }
        },
        taxPayerId: {
          label: 'CPF',
          factory: DocumentFactory,
          autocomplete: false,
          config: {
            id: 'taxpayer_id',
            placeholder: ' ',
            documentType: 'taxpayer_id',
            valid: false,
            disabled: true,
            lock: viewMode
          }
        },
        phoneOwner: {
          label: 'Telefone do Proprietário',
          factory: PhoneFactory,
          autocomplete: false,
          config: {
            id: 'owner-phone-number',
            placeholder: ' ',
            disabled: disabled
          }
        },
        description: {
          label: 'Descrição',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'description',
            placeholder: ' ',
            disabled: disabled
          }
        },
        emailOwner: {
          label: 'Email do Proprietário',
          factory: InputDefaultFactory,
          autocomplete: false,
          attrs: {
            maxLength: 254
          },
          config: {
            id: 'email-owner',
            placeholder: ' ',
            valid: false,
            disabled: true,
            lock: viewMode
          }
        },
        businessOpeningDate: {
          label: 'Data de Abertura',
          factory: InputDefaultFactory,
          autocomplete: false,
          config: {
            id: 'business-opening-date',
            placeholder: ' ',
            disabled: true,
            lock: viewMode
          }
        }
      }
    }

    if (showValidation) {
      options.fields = mapValues(options.fields, (field, key) => {
        const fieldValidation = this.getFieldValidation(key)

        return {
          ...field,
          hasError: !!fieldValidation,
          error: fieldValidation && fieldValidation.message
        }
      })
    }

    return (
      <Grid noPadding>
        <TitleInfoPage>Configurações do marketplace {'>'}</TitleInfoPage>
        <Grid.Row>
          <Grid.Col offset={1} cols={10}>
            <Title big noPadding>
              <strong>Dados Cadastrais</strong> do Marketplace
            </Title>
            {disabled && !hideEdit && (
              <ButtonEditWrapper
                ghost
                outline
                widthAuto
                onClick={this.editForm}
                icon='/assets/icons/actions/edit.svg'
                data-test='config-cadastro-edit-btn'
              >
                Editar
              </ButtonEditWrapper>
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col offset={1} cols={15}>
            <FormZ
              options={options}
              struct={this.struct}
              values={values}
              onChange={this.onChange}
              viewMode={viewMode}
              disabledForm={disabled}
            />
          </Grid.Col>
        </Grid.Row>
        {!disabled && (
          <Grid.Row>
            <Grid.Col offset={1} cols={1}>
              <Button
                onClick={this.cancelForm}
                inversed
                disabled={updateSellerOwnerRequest}
                data-test='config-cadastro-cancel-btn'
              >
                Cancelar
              </Button>
            </Grid.Col>
            <Grid.Col cols={2}>
              <Button
                isLoading={updateSellerOwnerRequest}
                type='submit'
                onClick={this.onSubmitForm}
                data-test='config-cadastro-save-btn'
              >
                Salvar Alterações
              </Button>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid>
    )
  }
}

AccountComponent.propTypes = {
  permissions: PropTypes.any,
  marketplaceId: PropTypes.string,
  marketplace: PropTypes.object,
  updateBusiness: PropTypes.func,
  sellerOwnerId: PropTypes.string,
  updateSellerOwnerRequest: PropTypes.bool,
  updateSellerOwnerSuccess: PropTypes.bool
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent)

const ButtonEditWrapper = styled(Button)`
  margin: -5px 0 -5px 10px;
`
